/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:db3eb9dc-0564-46f2-bbf6-3769e385902e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_2uo3sdE7t",
    "aws_user_pools_web_client_id": "6rpklv3qunlq85be3c5eiogvcm",
    "oauth": {},
    "aws_mobile_analytics_app_id": "84ce2d997243463f91c577e09d32476d",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
